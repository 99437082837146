export default function updateWordsList(input: string) {
  // Távolítsa el a felesleges szóközöket a vesszők után
  const cleanedInput = input.replace(/,\s+/g, ",");
  const cleanedDouble = cleanedInput.replace(",,", ",");

  // Ossza fel a szöveget vesszők mentén
  const words = cleanedDouble.split(",");

  // Használjon Set-et az ismétlődő szavak eltávolítására
  const uniqueWords = [...new Set(words)];

  // Alakítsa vissza a tömböt sztringgé, vesszővel elválasztva
  const result = uniqueWords.join(",");

  // Ha a string tartalmaz vesszőt, minden vessző után tegyen egy szóközt,
  // egyébként adja vissza változatlanul a stringet.
  const finalResult = result.includes(",")
    ? result.replace(/,/g, ", ")
    : result;

  return finalResult;
}
