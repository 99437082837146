import React from "react";

import { Typography, Box, Grid } from "@mui/material";
import SentencePlayButton from "./sentencePlayButtons";

export default function ActiveHelp(props: any) {
  const { tasks, tasksStatus } = props;
  const splitType = tasks[tasksStatus.currentTask].eng_usage_examples.includes("-") ? "-" : "*";
  const engSentences =
    tasks[tasksStatus.currentTask].eng_usage_examples.split(splitType);
  const hunSentences =
    tasks[tasksStatus.currentTask].hun_usage_examples.split(splitType);

  return (
    <Grid
      textAlign={"center"}
      p={1}
      sx={{ backgroundColor: "#121218", borderRadius: "10px" }}
      mb={1}
      item
      xs={12}
    >
      {engSentences.map((e: string, i: number) => {
        return (
          <Box mt={1} key={i}>
            <SentencePlayButton engSentence={e} />
            <Typography sx={{ color: "gold" }} mt={1} fontWeight={600}>
              {e}
            </Typography>
            <Typography mt={1} fontWeight={600}>
              {hunSentences[i]}
            </Typography>
          </Box>
        );
      })}
    </Grid>
  );
}
