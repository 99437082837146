import React, { useState } from "react";

import { Grid, Typography, Box } from "@mui/material";
import BorderLinearProgress from "../ui/progressBar";
import GetActiveForm from "./getActiveForm";
import PracticeWordsTooltip from "./practiceWordsTooltip";
import PlayButtons from "./playButtons";
import ActiveHelp from "./activeHelp";
import HelpSwitch from "./helpSwitch";

export default function Practise(props: any) {
  const {
    tasks,
    setTasks,
    tasksStatus,
    setTasksStatus,
    finishedTasks,
    setFinishedTasks,
    reportedWords,
    setReportedWords,
    studyTimeInSeconds
  } = props;
  const [isHelpActive, setIsHelpActive] = useState(false);
  const setHelpStatus = (e: any, checked: boolean) => {
    setIsHelpActive(checked);
  };

  const getCurrentProgressPercent = () => {
    if (typeof tasks !== "undefined") {
      const percent = (tasksStatus.currentProgress / tasks.length) * 100;
      return percent;
    }
  };

  return (
    <Grid item xs={12} sm={8} md={6} xl={4} p={2}>
      <Grid container>
        <Grid item xs={12}>
          <Typography textAlign={"center"} pt={2} variant="h5">
            Írd le a jelentését{" "}
            {tasksStatus.type === 0 ? "magyarul! 😊" : "angolul! 😊"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3} mb={2}>
            <BorderLinearProgress
              variant="determinate"
              value={getCurrentProgressPercent()}
            />
          </Box>
        </Grid>
        <Grid mb={1} item xs={12}>
          <HelpSwitch
            setHelpStatus={setHelpStatus}
            isHelpActive={isHelpActive}
          />
        </Grid>
        <Grid mt={2} my={1} item xs={12}>
          {isHelpActive && (
            <ActiveHelp tasks={tasks} tasksStatus={tasksStatus} />
          )}
        </Grid>
        <Grid mt={2} my={1} item xs={12}>
          {/* get play button start */}
          <PlayButtons tasks={tasks} tasksStatus={tasksStatus} />
          {/* get play button end */}
        </Grid>
        <Grid pl={1} item xs={12}>
          {/* if the user activate help switch start */}
          {/* if the user activate help switch end */}
          {/* get translate tooltip start */}
          <PracticeWordsTooltip tasks={tasks} tasksStatus={tasksStatus} />
          {/* get translate tooltip end */}
        </Grid>

        {/* get response forms start */}
        <GetActiveForm
          tasks={tasks}
          setTasks={setTasks}
          tasksStatus={tasksStatus}
          setTasksStatus={setTasksStatus}
          finishedTasks={finishedTasks}
          setFinishedTasks={setFinishedTasks}
          setIsHelpActive={setIsHelpActive}
          reportedWords={reportedWords}
          setReportedWords={setReportedWords}
          studyTimeInSeconds={studyTimeInSeconds}
        />
        {/* get response forms  end */}
      </Grid>
    </Grid>
  );
}
