import React, { useState, useEffect } from "react";

import CheckingWords from "../../service/practice/checkingWords";
import transformWords from "../../service/practice/transformWords";

import BadAnswerForm from "./forms/badAnswerForm";
import GoodAnswerForm from "./forms/goodAnswerForm";
import AnswerForm from "./forms/answerForm";
import playTheText from "../../service/practice/playText";

type TaskType = {
  w_id: number;
  w_rate: number;
  srs_level: number;
  re_practised: number;
  engToHunMistakes: number;
  hunToEngMistakes: number;
  practiceInSeconds: number;
};

export default function GetActiveForm(props: any) {
  const {
    tasks,
    tasksStatus,
    setTasks,
    setTasksStatus,
    finishedTasks,
    setFinishedTasks,
    setIsHelpActive,
    reportedWords,
    setReportedWords,
    studyTimeInSeconds,
  } = props;
  const [currentForm, setCurrentForm] = useState(0);
  const [isAnswerHandlerActive, setIsAnswerHandlerActive] = useState(false);
  const [realWordStudyTime, setRealWordStudyTime] = useState(0);
  const [formData, setFormData] = useState({
    answer: 0,
    dataChanged: false,
    isLoading: false,
    isFormDisabled: false,
  });

  const getNextTaskType = (notMaxEngTask: boolean, notMaxHunTask: boolean) => {
    const currentTypeNumber = Math.round(Math.random() * 1);
    if (currentTypeNumber === 0 && notMaxEngTask) {
      return currentTypeNumber;
    } else if (currentTypeNumber === 1 && notMaxHunTask) {
      return currentTypeNumber;
    } else if (currentTypeNumber === 0 && !notMaxEngTask) {
      return 1;
    } else if (currentTypeNumber === 1 && !notMaxHunTask) {
      return 0;
    } else {
      return currentTypeNumber;
    }
  };

  const nextTask = (wasGoodAnswer: boolean) => {
    // reset the answer form state
    setFormData((prevState) => ({
      ...prevState,
      answer: 0,
      dataChanged: false,
    }));
    const answer = document.getElementById("answer") as HTMLFormElement;
    if (tasks.length === tasksStatus.currentProgress) {
      // tasks are finished
      setTasksStatus((prevState: any) => ({
        ...prevState,
        type: 2, // reward page
      }));
    } else {
      answer.value = "";
      const isNotMaximumEngTask = tasksStatus.engToHunTasks < tasks.length / 2;
      const isNotMaximumHunTask = tasksStatus.hunToEngTasks < tasks.length / 2;
      const isLastTask = isNotMaximumEngTask || isNotMaximumHunTask;
      const getType =
        isLastTask && getNextTaskType(isNotMaximumEngTask, isNotMaximumHunTask);
      const safeValue =
        tasksStatus.currentTask + 1 === tasks.length
          ? tasks.length - 1
          : tasksStatus.currentTask + 1;
      setTasksStatus((prevState: any) => ({
        // go next task
        ...prevState,
        currentTask: wasGoodAnswer ? safeValue : prevState.currentTask,
        type: isLastTask ? getType : prevState.type,
        currentForm: 0,
        engToHunTasks:
          isNotMaximumEngTask && wasGoodAnswer && getType === 0
            ? prevState.engToHunTasks + 1
            : prevState.engToHunTasks,
        hunToEngTasks:
          isNotMaximumHunTask && wasGoodAnswer && getType === 1
            ? prevState.hunToEngTasks + 1
            : prevState.hunToEngTasks,
        totalTry: prevState.totalTry + 1,
      }));
      setCurrentForm(0);
    }
  };

  const userAswerHandler = async () => {
    try {
      setFormData((prevState: any) => ({
        ...prevState,
        isFormDisabled: true,
        isLoading: true,
      }));
      const getTasksStatus = props.tasksStatus;
      const allTasks = props.tasks;
      await playTheText("UK", allTasks[getTasksStatus.currentTask].eng);
      const answer = document.getElementById("answer") as HTMLFormElement;
      const userAnswer = answer.value;
      const currentFormType = getTasksStatus.type;
      // currentFormType = 0 magyar válaszokat vár, ha 1 akkor angolt
      const defaultAnswers =
        currentFormType === 0
          ? allTasks[getTasksStatus.currentTask].hun
          : allTasks[getTasksStatus.currentTask].eng;
      const nouns = allTasks[getTasksStatus.currentTask].hun_nouns;
      const verbs = allTasks[getTasksStatus.currentTask].hun_verbs;
      const adjectives = allTasks[getTasksStatus.currentTask].hun_adjectives;
      const adverbs = allTasks[getTasksStatus.currentTask].hun_adverbs;
      const pronouns = allTasks[getTasksStatus.currentTask].hun_pronouns;
      const prepositions =
        allTasks[getTasksStatus.currentTask].hun_prepositions;
      const conjunctions =
        allTasks[getTasksStatus.currentTask].hun_conjunctions;
      const numerals = allTasks[getTasksStatus.currentTask].hun_numerals;
      const determiners = allTasks[getTasksStatus.currentTask].hun_determiners;
      const interjections = allTasks[getTasksStatus.currentTask].interjections;
      const solutions: string =
        currentFormType === 0
          ? transformWords(
              defaultAnswers,
              nouns,
              verbs,
              adjectives,
              adverbs,
              pronouns,
              prepositions,
              conjunctions,
              numerals,
              determiners,
              interjections,
              allTasks[getTasksStatus.currentTask].hun_synonyms
            )
          : transformWords(
              defaultAnswers,
              allTasks[getTasksStatus.currentTask].eng_synonyms
            );
      setIsHelpActive(false); // disable help switch if it was active
      const [isCorrectAnswer, responseMsg, mistakePoint] = (await CheckingWords(
        solutions,
        userAnswer,
        currentFormType
      )) as [boolean, string, number];
      const successRatePercentPerTask = allTasks.length - mistakePoint;
      // isUsedTask default is not used, true if used
      const isUsedTask = allTasks[getTasksStatus.currentTask].isUsed;
      const isNotUsedTask = typeof isUsedTask === "undefined";
      const newSuccessRate = isNotUsedTask
        ? getTasksStatus.successRate + successRatePercentPerTask
        : getTasksStatus.successRate;
      const newTotalGoodAnswer = isNotUsedTask
        ? getTasksStatus.totalGoodAnswer + 1
        : getTasksStatus.totalGoodAnswer;
      const newSmallMistakesPoint =
        isNotUsedTask && mistakePoint > 0
          ? getTasksStatus.smallMistakes + 1
          : getTasksStatus.smallMistakes;
      if (isCorrectAnswer) {
        // good answer
        setTasksStatus((prevState: any) => ({
          ...prevState,
          currentProgress: prevState.currentProgress + 1,
          userAnswer: userAnswer,
          currentForm: 1,
          responseMsg,
          wasPerfectAnswer: mistakePoint === 0 ? true : false,
          successRate: newSuccessRate,
          totalGoodAnswer: newTotalGoodAnswer,
          smallMistakes: newSmallMistakesPoint,
        }));
        setFormData((prevState: any) => ({
          ...prevState,
          isFormDisabled: false,
          isLoading: false,
        }));
        setCurrentForm(1);
        setIsAnswerHandlerActive(false);
      } else {
        const newTotalWrongAnswer = isNotUsedTask
          ? getTasksStatus.totalWrongAnswer + 1
          : getTasksStatus.totalWrongAnswer;
        setTasksStatus((prevState: any) => ({
          // bad answer
          ...prevState,
          userAnswer: userAnswer,
          currentForm: 2,
          responseMsg,
          totalWrongAnswer: newTotalWrongAnswer,
        }));
        setFormData((prevState: any) => ({
          ...prevState,
          isFormDisabled: false,
          isLoading: false,
        }));
        setCurrentForm(2);
        setIsAnswerHandlerActive(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateGoodAnswerElement = (key: string | undefined) => {
    let userRate = 0;
    switch (key) {
      case "1":
        userRate = -2;
        break;
      case "2":
        userRate = -1;
        break;
      case "3":
        userRate = 1;
        break;
      case "4":
        userRate = 2;
        break;
    }

    const currentTask = tasks[tasksStatus.currentTask];
    const currentWordID = currentTask.w_id;
    const totalPracticeInSeconds = realWordStudyTime;
    const w_rate = userRate;

    const taskAlreadyExists = finishedTasks.some(
      (task: TaskType) => task.w_id === currentWordID
    );

    if (taskAlreadyExists) {
      // Ha létezik, frissítjük a meglévő taskot
      setFinishedTasks((prevWords: TaskType[]) =>
        prevWords.map((item: TaskType) =>
          item.w_id === currentWordID
            ? {
                ...item,
                re_practised: item.re_practised + 1,
                practiceInSeconds:
                  item.practiceInSeconds + totalPracticeInSeconds,
                w_rate: item.w_rate + w_rate,
                // hibák nem változnak jó válasz esetén
              }
            : item
        )
      );
    } else {
      // Ha még nem létezik, akkor újat hozunk létre
      setFinishedTasks((prevWords: TaskType[]) => [
        ...prevWords,
        {
          w_id: currentWordID,
          srs_level: currentTask.srs_level,
          re_practised: 1,
          w_rate: w_rate,
          engToHunMistakes: 0,
          hunToEngMistakes: 0,
          practiceInSeconds: totalPracticeInSeconds,
        },
      ]);
    }
    nextTask(true);
  };

  const updateWrongAnswerElement = () => {
    const currentFormType = tasksStatus.type;
    const currentTask = tasks[tasksStatus.currentTask];
    const currentWordID = currentTask.w_id;
    const totalPracticeInSeconds = realWordStudyTime;
    const w_rate = -1;
    const wasEngtoHun = currentFormType === 0 ? 1 : 0;
    const wasHuntoEng = currentFormType === 1 ? 1 : 0;

    // Közvetlenül ellenőrizd a finishedTasks jelenlegi állapotát
    const taskAlreadyExists = finishedTasks.some(
      (task: TaskType) => task.w_id === currentWordID
    );

    if (taskAlreadyExists) {
      // Már létezik, frissítsd megfelelően
      setFinishedTasks((prevWords: TaskType[]) =>
        prevWords.map((item: TaskType) =>
          item.w_id === currentWordID
            ? {
                ...item,
                re_practised: item.re_practised + 1,
                practiceInSeconds:
                  item.practiceInSeconds + totalPracticeInSeconds,
                w_rate: item.w_rate + w_rate,
                engToHunMistakes: item.engToHunMistakes + wasEngtoHun,
                hunToEngMistakes: item.hunToEngMistakes + wasHuntoEng,
              }
            : item
        )
      );
    } else {
      // Még nem létezik, újként add hozzá
      setFinishedTasks((prevWords: TaskType[]) => [
        ...prevWords,
        {
          w_id: currentWordID,
          srs_level: currentTask.srs_level,
          re_practised: 1,
          w_rate: w_rate,
          engToHunMistakes: wasEngtoHun,
          hunToEngMistakes: wasHuntoEng,
          practiceInSeconds: totalPracticeInSeconds,
        },
      ]);
    }
    pushWrongTaskToTasksEnd();
  };

  const pushWrongTaskToTasksEnd = () => {
    const allTasks = [...tasks]; 
    const currentTask = { ...allTasks[tasksStatus.currentTask], isUsed: true };
    allTasks.splice(tasksStatus.currentTask, 1);
    allTasks.push(currentTask);
    setTasks(allTasks);
    nextTask(false);
  };
  
  // megkapjuk a valós időt amit csak a szóra szán
  useEffect(() => {
    if (currentForm === 1 || currentForm === 2) {
      setRealWordStudyTime(studyTimeInSeconds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentForm]);

  useEffect(() => {
    if (currentForm === 0) {
      const answer = document.getElementById("answer") as HTMLFormElement;
      answer.focus();
    }
    const handlekeydownEvent = (event: KeyboardEvent) => {
      const isUnusedTask =
        typeof tasks[tasksStatus.currentTask].isUsed !== "boolean";
      const { key } = event;
      // answer form and answer lenght 0
      if (key === "Enter" && currentForm === 0 && formData.answer === 0) {
        event.preventDefault();
      }
      // answer form but user writed something
      else if (key === "Enter" && currentForm === 0 && formData.answer > 0) {
        event.preventDefault();
        setIsAnswerHandlerActive(true);
        userAswerHandler();
      }
      // if it was a good answer but not first encounter with the task
      else if (key === "Enter" && currentForm === 1 && !isUnusedTask) {
        event.preventDefault();
        updateGoodAnswerElement("3");
      }
      // good answer form and first encounter with the task
      else if (
        (key === "1" && currentForm === 1 && isUnusedTask) ||
        (key === "2" && currentForm === 1 && isUnusedTask) ||
        (key === "3" && currentForm === 1 && isUnusedTask) ||
        (key === "4" && currentForm === 1 && isUnusedTask)
      ) {
        event.preventDefault();
        updateGoodAnswerElement(key);
      }
      // bad answer form
      else if (key === "Enter" && currentForm === 2) {
        event.preventDefault();
        updateWrongAnswerElement();
      }
    };
    if (!isAnswerHandlerActive) {
      document.addEventListener("keydown", handlekeydownEvent);
      return () => {
        document.removeEventListener("keydown", handlekeydownEvent);
      };
    }
  });

  if (tasksStatus.currentForm === 0) {
    return (
      <AnswerForm
        tasksStatus={tasksStatus}
        formData={formData}
        setFormData={setFormData}
        userAswerHandler={userAswerHandler}
      />
    );
  } else if (tasksStatus.currentForm === 1) {
    return (
      <GoodAnswerForm
        tasks={tasks}
        tasksStatus={tasksStatus}
        updateGoodAnswerElement={updateGoodAnswerElement}
        nextTask={nextTask}
      />
    );
  } else {
    return (
      <BadAnswerForm
        tasks={tasks}
        tasksStatus={tasksStatus}
        updateWrongAnswerElement={updateWrongAnswerElement}
        reportedWords={reportedWords}
        setReportedWords={setReportedWords}
      />
    );
  }
}
