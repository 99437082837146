import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material/";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import newAxios from "../../../../../axios/axios";
import { AxiosResponse } from "axios";

export default function HomeworkPage() {
  const [isDataReady, setIsDataReady] = useState(false);
  const [currentPagiPage, setCurrentPagiPage] = useState(1);
  const [data, setData] = useState([]);
  const itemPerPage = 10;

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "lastUsed",
      headerName: "Gyakorolt",
      flex: 1,
      minWidth: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Típus",
      flex: 1.5,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isFinished",
      headerName: "Befejezve",
      flex: 1.5,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "successRate",
      headerName: "Eredmény",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "button",
      headerName: "Gyakorlás",
      flex: 1.5,
      minWidth: 140,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => getButton(params.value, params.row.isFinished),
    },
  ];

  function getButton(unique_id: string, isFinished: string): JSX.Element {
    if (isFinished === "Nem") {
      return (
        <Button
          className="homework-button-warn"
          href={"/app/practice/homework/" + unique_id}
          variant="contained"
          size="small"
        >
          Kezdjük!
        </Button>
      );
    } else {
      return (
        <Button
          className="homework-button-success"
          href={"/app/practice/homework/" + unique_id}
          variant="contained"
          size="small"
        >
          Ismétlés
        </Button>
      );
    }
  }

  const getAllUserHomeworkHistory = async () => {
    const response = (await newAxios(
      "/app/practice/words/homework/all",
      "GET"
    )) as AxiosResponse;
    if (response.status === 200) {
      let userHistory = response.data.map((history: any, index: number) => {
        return {
          id: index,
          lastUsed: history.last_used,
          type: history.type,
          isFinished: history.is_finished ? "Igen" : "Nem",
          successRate: history.success_rate + " %",
          button: history.unique_id,
        };
      });
      setData(userHistory);
      setIsDataReady(true);
    }
  };

  useEffect(() => {
    getAllUserHomeworkHistory();
  }, []);

  function paginationHandler(e: React.ChangeEvent<unknown>, page: number) {
    setCurrentPagiPage(page);
  }

  if (isDataReady) {
    return (
      <Box>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={itemPerPage}
          page={currentPagiPage - 1}
          autoHeight
          disableColumnMenu={true}
          hideFooter={true}
          density="comfortable"
          components={{
            NoRowsOverlay: () => (
              <Typography variant="h5" textAlign={"center"} mt={3}>
                Nincsenek leckék
              </Typography>
            ),
          }}
          sx={{
            fontWeight: 600,
            border: "none",
            color: "var(--myWhite)",
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus":
              {
                outline: "none",
              },
            ".MuiDataGrid-columnHeader": {
              backgroundColor: "var(--mySecondBg)",
              // borderRight: "1px solid gray",
            },
            "& .MuiDataGrid-columnHeaderTitle ": {
              color: "var(--myGold)",
              fontSize: 16,
              fontWeight: 600,
            },
            "& .MuiDataGrid-columnHeaderTitle:hover": {
              margin: 0,
              padding: 0,
            },
            "& .MuiDataGrid-cell ": {
              // border: "none",
              borderBottom: "1px solid grey",
              fontSize: 15,
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              // display: "none",
              color: "white",
            },
            // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            //   borderRight: '1px solid grey'
            // },
          }}
        />
        {/* if more history than itemPerPage show pagination */}
        <Grid container>
          <Grid mt={3} mb={1} mr={1} item xs={12}>
            {Math.ceil(data.length / itemPerPage) > 1 && (
              <Pagination
                sx={{ float: " right" }}
                className="pagination"
                count={Math.ceil(data.length / itemPerPage)}
                onChange={paginationHandler}
                page={currentPagiPage}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Grid textAlign={"center"} mt={5} item xs={12}>
          <CircularProgress size={"5rem"} />
        </Grid>
      </Grid>
    );
  }
}
