import React from "react";

import { Button, Typography, TextField, Grid } from "@mui/material";
import transformWords from "../../../service/practice/transformWords";
import updateWordsList from "../../../service/practice/updateWordsList";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportModalHandler from "./reportModal";

export default function BadAnswerForm(props: any) {
  const {
    tasks,
    tasksStatus,
    updateWrongAnswerElement,
    reportedWords,
    setReportedWords,
  } = props;
  const currentFormType = tasksStatus.type;
  const defaultAnswers = currentFormType === 0 ? tasks[tasksStatus.currentTask].hun : tasks[tasksStatus.currentTask].eng;
  const nouns = tasks[tasksStatus.currentTask].hun_nouns;
  const verbs = tasks[tasksStatus.currentTask].hun_verbs;
  const adjectives = tasks[tasksStatus.currentTask].hun_adjectives;
  const adverbs = tasks[tasksStatus.currentTask].hun_adverbs;
  const pronouns = tasks[tasksStatus.currentTask].hun_pronouns;
  const prepositions = tasks[tasksStatus.currentTask].hun_prepositions;
  const conjunctions = tasks[tasksStatus.currentTask].hun_conjunctions;
  const numerals = tasks[tasksStatus.currentTask].hun_numerals;
  const determiners = tasks[tasksStatus.currentTask].hun_determiners;
  const interjections = tasks[tasksStatus.currentTask].interjections ;
  const engSynonyms = tasks[tasksStatus.currentTask].eng_synonyms;
  const hunSynonyms = tasks[tasksStatus.currentTask].hun_synonyms;
  const solutions: string = currentFormType === 0 ? transformWords(defaultAnswers, nouns, verbs, adjectives, adverbs, pronouns, prepositions, conjunctions, numerals, determiners, interjections) :
  transformWords(defaultAnswers);

  return (
    <Grid textAlign={"center"} item xs={12}>
      <TextField
        className="form_input_field"
        margin="normal"
        fullWidth
        multiline
        rows={1}
        name="answer"
        id="answer"
        type="text"
        value={tasksStatus.userAnswer}
        required
        disabled
      />
      <Grid
        p={2}
        mb={2}
        xs={12}
        item
        sx={{ backgroundColor: "#121218", borderRadius: "10px" }}
      >
        <Grid container>
          <Grid m={"auto"} xs={12} item>
            <Typography
              fontSize={20}
              textAlign={"center"}
              sx={{ color: "var(--myAlert)" }}
              fontWeight={600}
            >
              {tasksStatus.responseMsg}
            </Typography>
          </Grid>
          <Grid m={"auto"} xs={12} item>
            <Typography
              fontSize={17}
              mt={1}
              textAlign={"center"}
              fontWeight={600}
            >
              Helyes megoldás(ok)
            </Typography>
            <Typography
              fontSize={18}
              mt={1}
              textAlign={"center"}
              sx={{ color: "#8bc34a" }}
              fontWeight={600}
            >
              {updateWordsList(solutions)}
            </Typography>
            <Typography
              fontSize={16}
              mt={1}
              textAlign={"center"}
              fontWeight={600}
            >
              Szinonímák:
            </Typography>
            <Typography
              fontSize={15}
              mt={1}
              textAlign={"center"}
              sx={{ color: "#8bc34a" }}
              fontWeight={600}
            >
              {updateWordsList(engSynonyms)}
            </Typography>
            <Typography
              fontSize={15}
              mt={1}
              textAlign={"center"}
              sx={{ color: "#8bc34a" }}
              fontWeight={600}
            >
              {updateWordsList(hunSynonyms)}
            </Typography>
            {tasks[tasksStatus.currentTask].usage_tips !== "" ? (
              <Typography
                fontSize={11}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
              >
                <TipsAndUpdatesIcon
                  sx={{
                    color: "yellow",
                    verticalAlign: "middle",
                    marginRight: 0.5,
                  }}
                ></TipsAndUpdatesIcon>
                {tasks[tasksStatus.currentTask].usage_tips}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid mt={2} container>
            <Grid m={"auto"} xs={5} item>
              {!reportedWords.includes(tasks[tasksStatus.currentTask].w_id) ? (
                <Button
                  size="small"
                  variant="text"
                  href="#"
                  sx={{ color: "goldenrod", float: "left" }}
                >
                  <ReportIcon></ReportIcon>
                  <ReportModalHandler
                    wordID={tasks[tasksStatus.currentTask].w_id}
                    setReportedWords={setReportedWords}
                    userAnswer={tasksStatus.userAnswer}
                  ></ReportModalHandler>
                </Button>
              ) : (
                <Typography variant="body1" tabIndex={0} sx={{ float: "left" }}>
                  <CheckCircleIcon
                    sx={{
                      verticalAlign: "middle",
                      marginRight: 0.3,
                      color: "yellowgreen",
                    }}
                  />
                  Hiba jelentve!
                </Typography>
              )}
            </Grid>
            <Grid m={"auto"} xs={5} item>
              <Button
                type="submit"
                size="large"
                variant="contained"
                className="form-button"
                sx={{ float: "right" }}
                onClick={updateWrongAnswerElement}
              >
                Tovább
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
