import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import newAxios from "../../../axios/axios";
import { StatusResponse } from "../../../models/response/status";

const ReportModal: React.FC<{
  open: boolean;
  onClose: () => void;
  wordID: number;
  setReportedWords: React.Dispatch<React.SetStateAction<number[]>>;
  userAnswer: string;
}> = ({ open, onClose, wordID, setReportedWords, userAnswer }) => {
  const [selectedErrors, setSelectedErrors] = useState<{
    [key: string]: boolean;
  }>({
    tipWrong: false,
    acceptAnswer: false,
    badAudio: false,
    firstSentenceWrong: false,
    secondSentenceWrong: false,
    thirdSentenceWrong: false,
    incorrectTranslation: false,
    otherIssue: false,
  });
  const [otherIssueText, setOtherIssueText] = useState("");

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedErrors({
      ...selectedErrors,
      [event.target.name]: event.target.checked,
    });

    if (event.target.name === "otherIssue" && !event.target.checked) {
      setOtherIssueText(""); // Reset text when unchecked
    }
  };

  const handleSubmit = async () => {
    let allErrors = {
      ...selectedErrors,
      otherIssueText: selectedErrors.otherIssue ? otherIssueText : null,
      wordID,
      userAnswer
    };
    const response = (await newAxios(
      "/app/practice/taskreport",
      "post",
      allErrors
    )) as StatusResponse;
    if (response.status === 200 && response.data.success) {
      setReportedWords((prev) => [...prev, wordID]);
      onClose();
    } else {
      onClose();
    }
  };

  const isSubmitDisabled =
    !Object.values(selectedErrors).some((value) => value) ||
    (selectedErrors.otherIssue && otherIssueText.trim().length === 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEnforceFocus
      PaperProps={{ style: { backgroundColor: "#121212", color: "#B0B0B0" } }}
    >
      <DialogTitle sx={{ color: "#B0B0B0" }}>
        Hibabejelentés
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "#B0B0B0" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="tipWrong"
                checked={selectedErrors.tipWrong}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label="Az egérrel előhívható szótári tipp hibás."
          />
          <FormControlLabel
            control={
              <Checkbox
                name="acceptAnswer"
                checked={selectedErrors.acceptAnswer}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label={<strong>A válaszomat el kellene fogadni.</strong>}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="badAudio"
                checked={selectedErrors.badAudio}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label="A hangfelvétel minősége nem megfelelő."
          />
          <FormControlLabel
            control={
              <Checkbox
                name="firstSentenceWrong"
                checked={selectedErrors.firstSentenceWrong}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label="Az első példamondat hibás."
          />
          <FormControlLabel
            control={
              <Checkbox
                name="secondSentenceWrong"
                checked={selectedErrors.secondSentenceWrong}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label="A második példamondat hibás."
          />
          <FormControlLabel
            control={
              <Checkbox
                name="thirdSentenceWrong"
                checked={selectedErrors.thirdSentenceWrong}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label="A harmadik példamondat hibás."
          />
          <FormControlLabel
            control={
              <Checkbox
                name="incorrectTranslation"
                checked={selectedErrors.incorrectTranslation}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label="Hibás fordítás."
          />
          <FormControlLabel
            control={
              <Checkbox
                name="otherIssue"
                checked={selectedErrors.otherIssue}
                onChange={handleCheckboxChange}
                sx={{ color: "#8BC34A", "&.Mui-checked": { color: "#8BC34A" } }}
              />
            }
            label={<strong>Más típusú hiba történt.</strong>}
          />
          {selectedErrors.otherIssue && (
            <>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Írd le saját szavaiddal"
                value={otherIssueText}
                onChange={(e) => setOtherIssueText(e.target.value.slice(0, 50))}
                inputProps={{ maxLength: 50 }}
                sx={{
                  mt: 2,
                  backgroundColor: "gray",
                  color: "#B0B0B0",
                  borderRadius: "10px",
                }}
              />
              <Typography sx={{ textAlign: "right", color: "#B0B0B0" }}>
                {50 - otherIssueText.length} karakter maradt
              </Typography>
            </>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          variant="contained"
          sx={{
            backgroundColor: isSubmitDisabled
              ? "#555!important"
              : "#8BC34A!important",
            color: "#121212",
            "&:hover": {
              backgroundColor: isSubmitDisabled
                ? "#555!important"
                : "#9CCC65!important",
            },
          }}
        >
          Beküldés
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ReportModalHandler = (props: {
  wordID: number;
  setReportedWords: React.Dispatch<React.SetStateAction<number[]>>;
  userAnswer: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reportButtonRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      <Typography
        ref={reportButtonRef}
        variant="body1"
        tabIndex={0}
        onClick={() => setIsModalOpen(true)}
        sx={{
          color: "#8BC34A",
          cursor: "pointer",
          textTransform: "none",
        }}
      >
        Jelentés
      </Typography>
      <ReportModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        wordID={props.wordID}
        setReportedWords={props.setReportedWords}
        userAnswer={props.userAnswer}
      />
    </div>
  );
};

export default ReportModalHandler;
