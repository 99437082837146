import React, { useState } from "react";

import {
  Typography,
  Tooltip,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import InfoIcon from "@mui/icons-material/Info";

export default function PracticeWordsTooltip(props: any) {
  const { tasks, tasksStatus } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const handleClickedTooltip = () => {
    setIsOpen(true);
    setIsClicked(true);
  };
  const handleClickedTooltipClose = () => {
    setIsOpen(false);
    setIsClicked(false);
  };
  const handleTooltipClose = () => {
    if (!isClicked) {
      setIsOpen(false);
    }
  };
  const handleTooltipOpen = () => {
    setIsOpen(true);
  };
  const getWords = (type: string | undefined) => {
    if (type) {
      const checkLength = type.includes(",");
      if (checkLength) {
        const words = checkLength ? type.replaceAll(",", ", ") : type;
        return words;
      } else {
        return type;
      }
    }
  };

  const getPronunciation = () => {
    const pronuns =
      tasks[tasksStatus.currentTask].pronunciation.startsWith("/") &&
      tasks[tasksStatus.currentTask].pronunciation.endsWith("/")
        ? ` ${tasks[tasksStatus.currentTask].pronunciation} `
        : ` /" ${tasks[tasksStatus.currentTask].pronunciation}/ `;
    const withHunPronun =
      pronuns + `(${tasks[tasksStatus.currentTask].hun_pronunciation})`;
    return withHunPronun;
  };

  const getEngTooltipValue = function (words: string | undefined) {
    const task = tasks[tasksStatus.currentTask];

    return (
      <TableContainer
        component={Paper}
        sx={{
          padding: 0.5,
          minWidth: 380,
          maxWidth: "auto",
          maxHeight: 350,
          overflow: "auto",
          backgroundColor: "#1e1e1e",
          border: "1px solid #8bc34a",
        }}
      >
        <Table>
          <TableBody>
            {task.level && task.word_type && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Szint - Típus</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  ({task.level}) - {task.word_type}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={{ color: "#8bc34a" }}>Fordítása(i)</TableCell>
              <TableCell sx={{ color: "#d1d1d1" }}>{getWords(words)}</TableCell>
            </TableRow>
            {task.eng_plural && task.hun_plural && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Többes szám</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_plural} ({task.hun_plural})
                </TableCell>
              </TableRow>
            )}
            {task.eng_irregular && task.hun_irregular && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>
                  Rendhagyó alakok
                </TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_irregular} ({task.hun_irregular})
                </TableCell>
              </TableRow>
            )}
            {task.hun_nouns && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Főnevek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_nouns)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_verbs && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Igék</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_verbs)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_adjectives && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Melléknevek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_adjectives)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_adverbs && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Határozószók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_adverbs)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_pronouns && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Névmások</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_pronouns)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_prepositions && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Előjárószók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_prepositions)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_conjunctions && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Kötőszók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_conjunctions)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_numerals && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Számnevek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_numerals)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_determiners && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Névutók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_determiners)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_interjections && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Indulatszók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_interjections)}
                </TableCell>
              </TableRow>
            )}
            {task.eng_synonyms && task.hun_synonyms && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Szinonimák</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_synonyms} ({task.hun_synonyms})
                </TableCell>
              </TableRow>
            )}
            {task.eng_antonyms && task.hun_antonyms && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Ellentétek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_antonyms} ({task.hun_antonyms})
                </TableCell>
              </TableRow>
            )}
            {task.eng_collocations && task.hun_collocations && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Szókapcsolatok</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_collocations} ({task.hun_collocations})
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Ez a box az magyar szó tooltip
  const getHunTooltipValue = function (words: string | undefined) {
    const task = tasks[tasksStatus.currentTask];

    return (
      <TableContainer
        component={Paper}
        sx={{
          padding: 0.5,
          minWidth: 380,
          maxWidth: "auto",
          maxHeight: 350,
          overflow: "auto",
          backgroundColor: "#1e1e1e",
          border: "1px solid #8bc34a",
        }}
      >
        <Table>
          <TableBody>
            {task.level && task.word_type && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Szint - Típus</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  ({task.level}) - {task.word_type}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={{ color: "#8bc34a" }}>Fordítása(i)</TableCell>
              <TableCell sx={{ color: "#d1d1d1" }}>{getWords(words)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: "#8bc34a" }}>Kiejtés</TableCell>
              <TableCell sx={{ color: "#d1d1d1" }}>
                {getPronunciation()}
              </TableCell>
            </TableRow>
            {task.eng_plural && task.hun_plural && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Többes szám</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_plural} ({task.hun_plural})
                </TableCell>
              </TableRow>
            )}
            {task.eng_irregular && task.hun_irregular && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>
                  Rendhagyó alakok
                </TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_irregular} ({task.hun_irregular})
                </TableCell>
              </TableRow>
            )}
            {task.hun_nouns && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Főnevek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_nouns)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_verbs && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Igék</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_verbs)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_adjectives && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Melléknevek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_adjectives)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_adverbs && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Határozószók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_adverbs)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_pronouns && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Névmások</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_pronouns)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_prepositions && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Előjárószók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_prepositions)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_conjunctions && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Kötőszók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_conjunctions)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_numerals && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Számnevek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_numerals)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_determiners && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Névutók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_determiners)}
                </TableCell>
              </TableRow>
            )}
            {task.hun_interjections && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Indulatszók</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {getWords(task.hun_interjections)}
                </TableCell>
              </TableRow>
            )}
            {task.eng_synonyms && task.hun_synonyms && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Szinonimák</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_synonyms} ({task.hun_synonyms})
                </TableCell>
              </TableRow>
            )}
            {task.eng_antonyms && task.hun_antonyms && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Ellentétek</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_antonyms} ({task.hun_antonyms})
                </TableCell>
              </TableRow>
            )}
            {task.eng_collocations && task.hun_collocations && (
              <TableRow>
                <TableCell sx={{ color: "#8bc34a" }}>Szókapcsolatok</TableCell>
                <TableCell sx={{ color: "#d1d1d1" }}>
                  {task.eng_collocations} ({task.hun_collocations})
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickedTooltipClose}>
        <Tooltip
          title={
            tasksStatus.type === 1
              ? getHunTooltipValue(tasks[tasksStatus.currentTask].eng)
              : getEngTooltipValue(tasks[tasksStatus.currentTask].hun)
          }
          sx={{ color: "red!important" }}
          placement="bottom"
          arrow
          open={isOpen}
          onClick={handleClickedTooltip}
          onMouseLeave={handleTooltipClose}
          onMouseEnter={handleTooltipOpen}
          disableHoverListener
          slotProps={{
            tooltip: {
              sx: {
                padding: 0,
                marginLeft: 2,
                color: "#514E6A",
              },
            },
          }}
          PopperProps={{
            sx: {
              "& .MuiTooltip-arrow": {
                fontSize: 25,
                "&::before": {},
              },
            },
          }}
        >
          <span>
            <Typography
              sx={{
                cursor: "pointer",
                display: "inline",
              }}
              color="greenyellow"
              fontSize={20}
            >
              🔎
              {getWords(
                tasksStatus.type === 1
                  ? tasks[tasksStatus.currentTask].hun
                  : tasks[tasksStatus.currentTask].eng
              )}
            </Typography>
            {tasksStatus.type === 0 && (
              <Typography
                sx={{ display: "inline", marginLeft: 1 }}
                color="#d1d1d1"
                fontWeight={600}
              >
                {tasksStatus.type === 0 && getPronunciation()}
              </Typography>
            )}
          </span>
        </Tooltip>
      </ClickAwayListener>
      {tasks[tasksStatus.currentTask].hun_definition !== "" ? (
        <Typography pt={1} fontSize={11}>
          <InfoIcon
            sx={{
              color: "goldenrod",
              verticalAlign: "middle",
              marginRight: 0.5,
            }}
          ></InfoIcon>
          {tasks[tasksStatus.currentTask].hun_definition}
        </Typography>
      ) : (
        ""
      )}
    </div>
  );
}
