let isVoiceActive = false;

const getUserBrowser = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else {
    return "unknown";
  }
};

const playTheText = (target: string, word: string) => {
  return new Promise<void>((res) => {
    if (!isVoiceActive) {
      window.speechSynthesis.cancel(); // Chrome fix
      isVoiceActive = true;

      const synth = window.speechSynthesis;
      let voices: SpeechSynthesisVoice[], utterance: SpeechSynthesisUtterance;
      utterance = new SpeechSynthesisUtterance(word);

      const lang = target === "US" ? "en-US" : "en-GB";

      const setTheVoiceBasedOnBrowser = () => {
        const userBrowser = getUserBrowser();

        voices = synth.getVoices().filter((v) => v.lang === lang);

        if (voices.length > 0) {
          // random voice if multiple available
          utterance.voice = voices[Math.floor(Math.random() * voices.length)];
        }
        utterance.lang = lang;
      };

      const checkTheVoicesLength = () => {
        voices = synth.getVoices();
        if (voices.length === 0) {
          setTimeout(checkTheVoicesLength, 100);
        } else {
          setTheVoiceBasedOnBrowser();
          synth.speak(utterance);
          utterance.onend = () => {
            isVoiceActive = false;
            synth.cancel();
            res();
          };
        }
      };

      checkTheVoicesLength();
    }
  });
};

export default playTheText;
