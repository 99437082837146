function transformWords(...inputWords: string[]): string {
  // A Set nem engedi, hogy ugyanaz a szó többször bekerüljön
  const uniqueWords = new Set<string>();

  for (const words of inputWords) {
    if (!words) {
      continue;
    }

    // Első lépés: vessző mentén darabolunk
    const splittedByComma = words.split(",");

    for (let part of splittedByComma) {
      // Zárójelek és a közöttük lévő szöveg eltávolítása
      part = part.replace(/\(.*?\)/g, "");
      // Perjel (/) lecserélése vesszőre
      part = part.replace(/\//g, ",");
      // Fölösleges szóközök levágása
      part = part.trim();

      if (!part) {
        continue;
      }

      // Lehetséges, hogy több vessző is keletkezett (pl. "öcs/báty" → "öcs,báty")
      const finalParts = part
        .split(",")
        .map((p) => p.trim())
        .filter(Boolean);

      // Hozzáadás a Set-hez
      for (const item of finalParts) {
        uniqueWords.add(item);
      }
    }
  }

  // A Set-ből tömb, majd összefűzzük vesszővel
  const solutions = Array.from(uniqueWords).join(",");
  return solutions;
}

export default transformWords;
