import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Divider,
  Grid,
  CircularProgress,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import newAxios from "../../axios/axios";
import { AxiosResponse } from "axios";
import { UserRewardType } from "../../models/response/afterLogin/practice/userReward";
import getMotivationalMessage from "./motivationMessages";

// ---------------------------------------------------------
// 2) Alkomponens: Tűz‐animáció + széria animált kiírása
// ---------------------------------------------------------
function StreakCounter({
  oldStreak,
  newStreak,
  flameAnimation = "/animations/fire.gif",
}: {
  oldStreak: number;
  newStreak: number;
  flameAnimation?: string;
}) {
  const [streak, setStreak] = useState(oldStreak);

  useEffect(() => {
    // 1 mp múlva váltunk a "régi" szériáról a "új" szériára => animáció
    const timer = setTimeout(() => {
      setStreak(newStreak);
    }, 1000);
    return () => clearTimeout(timer);
  }, [newStreak]);

  return (
    <Box
      sx={{
        backgroundColor: "#121218",
        padding: 3,
        borderRadius: 2,
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      <Typography variant="h6" fontWeight="bold" color={"orange"}>
        Gratulálok!
      </Typography>
      <Typography variant="h6" fontWeight="bold" color={"orange"}>
        növelted a szériád!
      </Typography>

      {/* Tűz animáció */}
      <img
        src={flameAnimation}
        alt="Flame icon"
        style={{ width: 250, height: 250 }}
      />
      {/* Széria‐szám egyszerűbben */}
      <Box
        sx={{
          borderRadius: 2,
        }}
      >
        <AnimatePresence mode="popLayout">
          <motion.span
            key={streak}
            style={{
              fontSize: "3rem",
              fontWeight: "bold",
              display: "inline-block",
              color: "orange",
            }}
            initial={{ y: 20, opacity: 0, scale: 0.8 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: -20, opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {streak}
          </motion.span>
          <Typography
            sx={{ fontSize: "1.5rem" }}
            variant="body1"
            mb={1}
            color={"orange"}
          >
            napos széria!
          </Typography>
        </AnimatePresence>
      </Box>
    </Box>
  );
}

function getAccuracyColor(accuracy: number) {
  if (accuracy > 75) return "greenyellow"; // Zöld
  if (accuracy >= 50) return "#FFA726"; // Narancs
  return "#F44336"; // Piros
}

// ---------------------------------------------------------
// 3) A FŐKOMPONENS, két lépésre bontva: Step1 (széria), Step2 (jutalom)
// ---------------------------------------------------------
export default function RewardPage(props: any) {
  const { finishedTasks, tasksStatus, target } = props;
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [userReward, setUserReward] = useState<UserRewardType | null>(null);
  const navigate = useNavigate();
  const navigateRef = React.useRef(navigate);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    if (isLoading) return;

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        window.removeEventListener("keydown", handleKeyUp); // csak egyszer
        navigateRef.current("/app"); //
      }
    };

    window.addEventListener("keydown", handleKeyUp);
    return () => window.removeEventListener("keydown", handleKeyUp);
  }, [isLoading]);

  // Adatok lekérése a backendről
  const getRewards = async () => {
    try {
      const getRoute = (): string => {
        if (target === "words_practice") return "/app/practice/words/reward";
        if (target === "homework_practice")
          return "/app/practice/homework/reward";
        return "/app/practice/srs/reward";
      };

      const response = (await newAxios(getRoute(), "POST", {
        tasks: finishedTasks,
        token,
        successRate: tasksStatus.successRate,
        totalGoodAnswer: tasksStatus.totalGoodAnswer,
        totalWrongAnswer: tasksStatus.totalWrongAnswer,
        smallMistakes: tasksStatus.smallMistakes,
        totalStudyTimeInSeconds: tasksStatus.totalStudyTimeInSeconds,
      })) as AxiosResponse<UserRewardType>;

      if (response.status === 200) {
        setUserReward(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function getWeekdaysStatus() {
    const days = userReward?.days;
    const isoDay = new Date().getDay(); // 0 (V) – 6 (Szo)
    const todayIndex = isoDay === 0 ? 6 : isoDay - 1;

    if (!days) return null;

    const weekdays = ["H", "K", "Sz", "Cs", "P", "Szo", "V"]; // Hét napjai magyarul

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#121218",
          border: "1px solid #ffffff33",
          borderRadius: "8px",
          padding: "12px",
          marginTop: "5px",
        }}
      >
        {/* Hét napjainak rövidítései */}
        <Box sx={{ display: "flex", gap: "10px", marginBottom: "6px" }}>
          {weekdays.map((day, index) => (
            <Typography
              key={index}
              sx={{
                color: index === todayIndex ? "orange" : "#ffffff88",
                fontWeight: index === todayIndex ? "700!important" : "400!important",
                fontSize: "14px",
                textAlign: "center",
                width: "30px",
              }}
            >
              {day}
            </Typography>
          ))}
        </Box>

        {/* Napok állapota */}
        <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          {days.map((status, index) => (
            <Box
              key={index}
              sx={{
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {status === 1 ? (
                <img
                  src="/animations/fire.gif" // Ha a nap értéke 1, akkor láng ikon
                  alt="streak"
                  style={{ width: "40px", height: "40px" }}
                />
              ) : status === 2 ? (
                <img
                  src="/icons/userbox/08.png" // Ha a nap értéke 2, akkor másik ikon
                  alt="frozen-streak"
                  style={{ width: "30px", height: "30px" }}
                />
              ) : (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#ffffff33",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Box>
          ))}
        </Box>

        {/* Divider vonal */}
        <Divider
          sx={{
            width: "100%",
            backgroundColor: "#ffffff33",
            marginBottom: "10px",
          }}
        />

        {/* Szöveges instrukció */}
        <Typography
          sx={{
            color: "#ffffffaa",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Gyakorolj mindennap, hogy ne vesszen el a szériád!
        </Typography>
      </Box>
    );
  }

  function getRewardsInfo() {
    return (
      <Box
        textAlign="center"
        sx={{
          backgroundColor: "#121218",
          borderRadius: "10px",
          color: "white",
          mt: 2,
        }}
      >
        {/* Leírás */}

        {/* Két doboz (Össztapasztalat & Pontosság) */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 4 }}>
          {/* Össztapasztalat */}
          <Box
            sx={{
              width: "98%",
              maxWidth: "180px",
              backgroundColor: "#FFB400",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {/* Felső sáv */}
            <Box
              sx={{
                backgroundColor: getAccuracyColor(userReward?.successRate!),
                padding: "6px",
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Össztapasztalat
            </Box>
            {/* Belső tartalom */}
            <Box
              sx={{
                backgroundColor: "#121218",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <img
                src="/icons/userbox/09.png"
                alt="xp icon"
                style={{ width: 30, height: 30, marginRight: 8 }}
              />
              <Typography
                variant="h6"
                fontWeight="bold"
                color={getAccuracyColor(userReward?.successRate!)}
              >
                + {userReward?.rewardXP} TP
              </Typography>
            </Box>
          </Box>

          {/* Pontosság */}
          <Box
            sx={{
              width: "98%",
              maxWidth: "180px",
              backgroundColor: "#4CAF50",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {/* Felső sáv */}
            <Box
              sx={{
                backgroundColor: getAccuracyColor(userReward?.successRate!),
                padding: "6px",
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Pontosság
            </Box>
            {/* Belső tartalom */}
            <Box
              sx={{
                backgroundColor: "#121218",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <img
                src="/icons/userbox/10.png"
                alt="accuracy icon"
                style={{ width: 30, height: 30, marginRight: 8 }}
              />
              <Typography
                variant="h6"
                fontWeight="bold"
                color={getAccuracyColor(userReward?.successRate!)}
              >
                {userReward?.successRate} %
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />
      </Box>
    );
  }

  useEffect(() => {
    getRewards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ha töltés alatt vagy nincs userReward
  if (isLoading || !userReward) {
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid textAlign="center" mt={5} item>
          <CircularProgress size="5rem" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ backgroundColor: "#20232A", minHeight: "100vh" }}
    >
      <Grid item p={1} xs={12} sm={8} md={6} lg={3}>
        <Box
          textAlign="center"
          sx={{
            backgroundColor: "#121218",
            border: "1px solid #ffffff33",
            borderRadius: "8px",
            color: "white",
            padding: 1,
            mt: 2,
          }}
        >
          {/* Ha tényleg új széria, mutatjuk az animációt userReward.newDailySeries */}
          {userReward.newDailySeries ? (
            <StreakCounter
              oldStreak={userReward.daily_series - 1}
              newStreak={userReward.daily_series}
            />
          ) : (
            <Box>
              <Typography
                variant="h5"
                fontWeight="bold"
                my={1}
                color={"orange"}
              >
                Gratulálok!
              </Typography>
              <Typography variant="h6" fontWeight="bold" color={"orange"}>
                Egy újabb lépéssel közelebb kerültél a célodhoz!
              </Typography>
            </Box>
          )}
          <Box mt={2}>{getWeekdaysStatus()}</Box>
          <Box mt={3}>{getRewardsInfo()}</Box>
          <Box my={3}>
            <Typography fontWeight="bold">
              {getMotivationalMessage(userReward?.successRate)}
            </Typography>
          </Box>
          {/* Tovább a főoldalra gomb */}
          <Button
            variant="contained"
            color="warning"
            style={{ marginBottom: 10, marginTop: 10 }}
            href="/app/"
          >
            Tovább
          </Button>
          {/* Tovább gomb => Step2 */}
        </Box>
      </Grid>
    </Grid>
  );
}
