export default function CheckingWords(
  solutions: string,
  answer: string,
  formType: number
): Promise<[boolean, string, number]> {
  return new Promise((res: any) => {
    // check the type of form (0 eng to hun, 1 hun to eng)
    const isEngAnswer = formType === 0 ? false : true;
    const trimmedAnswer = answer.trim();
    // lowercase all char after the first char (needed if the solution wait a first Uppercase char)
    const keepOnlyFirstCharUpper =
      trimmedAnswer[0] + trimmedAnswer.slice(1).toLowerCase();
    // check the solutions have uppercase
    let hasSolutionUppercase = /[A-Z]/.test(solutions);
    const lowercaseAnswer = hasSolutionUppercase
      ? keepOnlyFirstCharUpper
      : trimmedAnswer.toLocaleLowerCase();
    // check the answer uppercase and uppercase accent characters after the first character
    const hasItUppercaseCharAfterFirst = /[A-ZÁÉÍÓÖŐÚÜŰ]/.test(
      trimmedAnswer.slice(1)
    );
    // maximum mistate per answer
    const maxMistakePerAnswer = 1;
    // remove successRate points from the total
    const mistakePoint = 2;
    // check single or more solutions are available
    const moreSolutions = solutions.includes(",");
    const isIncludeWrongCharacters = /[^A-Za-zÁÉÍÓÖŐÚÜŰáéíóöőúüű \-']/.test(
      trimmedAnswer
    );
    if (isIncludeWrongCharacters) {
      res([false, responseMessages.wrong, 0]);
    }
    // if there is more than one solution
    else if (moreSolutions) {
      const allSolutions = solutions.split(",");
      const trimmedSolutions = allSolutions.map((item) => item.trim());
      // sort solutions by string length smaller to bigger
      const sortedSolutions = trimmedSolutions.sort(
        (a, b) => a.length - b.length
      );
      for (let i = 0; i < sortedSolutions.length; i++) {
        let trimmedSolution = sortedSolutions[i].trim();
        let isInclude = trimmedSolution.includes(lowercaseAnswer);
        const isEqual = trimmedAnswer === trimmedSolution;
        const answerHasTwoWordsAndSpace = /\s/.test(trimmedAnswer);
        const SolutionHasTwoWordsAndSpace = /\s/.test(trimmedSolution);
        const zeroWhiteSpaceInAnswer = answerHasTwoWordsAndSpace && trimmedAnswer.replace(/\s/g, '');
        const zeroWhiteSpaceInSolution = SolutionHasTwoWordsAndSpace && trimmedSolution.replace(/\s/g, '');
        // if it is isEngAnswer -> check the answer is include an accent character and switch to normal if it has it
        // else check the solution is include an accent character and switch to normal if it has it
        const getActualIncludeTest = isEngAnswer
          ? trimmedSolution.includes(
              lowercaseAnswer.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            )
          : trimmedSolution
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(lowercaseAnswer);
        // if the answer and solution totally equal
        if (isEqual) {
          res([true, responseMessages.correct, 0]);
          break;
        }
        // if the answer equal and no uppercase after first char
        else if (
          isInclude &&
          lowercaseAnswer.length === trimmedSolution.length &&
          !hasItUppercaseCharAfterFirst
        ) {
          res([true, responseMessages.correct, 0]);
          break;
        }
        // if the answer and solution totally equal but include an uppercase char not in the first position
        else if (
          isInclude &&
          lowercaseAnswer.length === trimmedSolution.length &&
          hasItUppercaseCharAfterFirst
        ) {
          res([true, responseMessages.goodButWrongUppercaseChar, mistakePoint]);
          break;
        }
        // if the answer and solution totally equal but include an (uppercase + accent) char not in the first position
        else if (
          lowercaseAnswer.length >= trimmedSolution.length &&
          hasItUppercaseCharAfterFirst &&
          getActualIncludeTest
        ) {
          res([true, responseMessages.goodWithoutAccentAndUpper, mistakePoint]);
          break;
        }
        // if the answer almost correct but the mistate is smaller or equal with the maxFault
        else if (
          isInclude &&
          lowercaseAnswer.length >= trimmedSolution.length - maxMistakePerAnswer
        ) {
          res([true, responseMessages.almostCorrect, mistakePoint]);
          break;
        }
        // if the answer correct without accents
        else if (
          getActualIncludeTest &&
          lowercaseAnswer.length === trimmedSolution.length
        ) {
          const responseMsg = isEngAnswer
            ? responseMessages.goodWithoutAccentFix
            : responseMessages.goodWithAccentFix;
          res([true, responseMsg, mistakePoint]);
          break;
        }
        // The original solution has two words and white space but it equals with user answer if no space in solution
        else if(SolutionHasTwoWordsAndSpace && (zeroWhiteSpaceInSolution === trimmedAnswer)){
          res([true, responseMessages.correctButMissingWhiteSpace, mistakePoint]);
          break;
        }
        // the original answer has two words and white space but it equals with solution if no space in
        else if(answerHasTwoWordsAndSpace && (zeroWhiteSpaceInAnswer === trimmedSolution)){
          res([true, responseMessages.correctWithoutWhiteSpace, mistakePoint]);
          break;
        }
        // compare the answer and the solution index characters
        else if (
          answerCharactersCheck(
            lowercaseAnswer,
            trimmedSolution,
            maxMistakePerAnswer
          )
        ) {
          res([true, responseMessages.almostCorrect, mistakePoint]);
          break;
        }
        // all answer was wrong
        else if (i === sortedSolutions.length - 1) {
          res([false, responseMessages.wrong, 0]);
          break;
        }
      }
    }
    // if only one solution
    else {
      const trimmedSolution = solutions.trim();
      const isEqual = trimmedAnswer === trimmedSolution;
      // if it is isEngAnswer -> check the answer is include an accent character and switch to normal if it has it
      // else check the solution is include an accent character and switch to normal if it has it
      const getActualIncludeTest = isEngAnswer
        ? trimmedSolution.includes(
            lowercaseAnswer.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          )
        : trimmedSolution
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(lowercaseAnswer);
      const isInclude = trimmedSolution.includes(lowercaseAnswer);
      // if the answer and solution totally equal
      if (isEqual) {
        res([true, responseMessages.correct, 0]);
      }
      // if the answer equal and no uppercase after first char
      else if (
        isInclude &&
        lowercaseAnswer.length === trimmedSolution.length &&
        !hasItUppercaseCharAfterFirst
      ) {
        res([true, responseMessages.correct, 0]);
      }
      // if the answer and solution totally equal but include an uppercase char not in the first position
      else if (
        isInclude &&
        lowercaseAnswer.length === trimmedSolution.length &&
        hasItUppercaseCharAfterFirst
      ) {
        res([true, responseMessages.goodButWrongUppercaseChar, mistakePoint]);
      }
      // if the answer and solution totally equal but include an (uppercase + accent) char not in the first position
      else if (
        lowercaseAnswer.length >= trimmedSolution.length &&
        hasItUppercaseCharAfterFirst &&
        getActualIncludeTest
      ) {
        res([true, responseMessages.goodWithoutAccentAndUpper, mistakePoint]);
      }
      // if the answer almost correct but the mistate is smaller or equal with the maxFault
      else if (
        isInclude &&
        lowercaseAnswer.length >= trimmedSolution.length - maxMistakePerAnswer
      ) {
        res([true, responseMessages.almostCorrect, mistakePoint]);
      }
      // if the answer correct without accents
      else if (
        getActualIncludeTest &&
        lowercaseAnswer.length === trimmedSolution.length
      ) {
        const responseMsg = isEngAnswer
          ? responseMessages.goodWithoutAccentFix
          : responseMessages.goodWithAccentFix;
        res([true, responseMsg, mistakePoint]);
      }
      // compare the answer and the solution index characters
      else if (
        answerCharactersCheck(
          lowercaseAnswer,
          trimmedSolution,
          maxMistakePerAnswer
        )
      ) {
        // this will help to check first Uppercase solutions < starts
        const isFirstCharUppercase = /[A-ZÁÉÍÓÖŐÚÜŰ]/.test(lowercaseAnswer[0]);
        const isOtherCharsUppercase = /[A-ZÁÉÍÓÖŐÚÜŰ]/.test(
          lowercaseAnswer.slice(1)
        );
        const situationMsg =
          hasSolutionUppercase &&
          (!isFirstCharUppercase || isOtherCharsUppercase)
            ? responseMessages.goodButWrongUppercaseChar
            : responseMessages.almostCorrect;
        // this will help to check first Uppercase solutions < end
        res([true, situationMsg, mistakePoint]);
      }
      // wrong answer
      else {
        res([false, responseMessages.wrong, 0]);
      }
    }
  });
}

const correctMessages = () => {
  const messages = [
    "Helyes válasz!",
    "Tőkéletes válasz!",
    "Szuper vagy!",
    "Hibátlan válasz!",
  ];
  const getMsgIndex = Math.floor(Math.random() * messages.length);
  return messages[getMsgIndex];
};

const wrongMessages = () => {
  const messages = ["Rossz válasz!", "Hibás válasz!", "Ez most nem talált :("];
  const getMsgIndex = Math.floor(Math.random() * messages.length);
  return messages[getMsgIndex];
};

const answerCharactersCheck = (
  userAnswer: string,
  taskSolution: string,
  mistakePerAnswer: number
) => {
  let goodChars = 0;
  let wrongChars = 0;
  for (let i = 0; i < userAnswer.length; i++) {
    userAnswer[i] === taskSolution[i] ? goodChars++ : wrongChars++;
    if (
      i === userAnswer.length - 1 &&
      goodChars + wrongChars <= taskSolution.length &&
      goodChars >= taskSolution.length - mistakePerAnswer
    ) {
      return true;
    } else if (i === userAnswer.length - 1) {
      return false;
    }
  }
};

const responseMessages = {
  correct: correctMessages(),
  wrong: wrongMessages(),
  almostCorrect: "Majdnem jó, csak elírtad",
  goodWithAccentFix: "Majdnem jó. Hiányzó ékezet(ek)!",
  goodWithoutAccentFix: "Ékezet(ek) nélkül tökéletes lenne!",
  goodButWrongUppercaseChar: "Majdnem jó figyelj a kis/nagybetükre",
  goodWithoutAccentAndUpper:
  "Ékezet(ek) és nagybetü(k) nélkül tökéletes lenne!",
  correctButMissingWhiteSpace: "Majdnem jó figyelj rá, hogy külön írjuk!",
  correctWithoutWhiteSpace: "Majdnem jó figyelj rá, hogy egybe írjuk!",
};
