const motivationalMessages = {
  excellent: [
    "🎉 Lenyűgöző munka! Az agyad egyre jobban alkalmazkodik a tanuláshoz!",
    "🌟 A következetesség meghozza a gyümölcsét! Csak így tovább, már most nagyszerű eredményeket érsz el!",
    "🚀 A tanulásod exponenciálisan fejlődik! Minél többet gyakorolsz, annál gyorsabban rögzülnek az új szavak!",
    "📚 Tudtad? Ha napi 10 percet gyakorolsz, az éves szinten több mint 60 óra nyelvtanulás!",
    "🧠 Agyi kutatások bizonyítják: A következetes tanulás 21-30 nap alatt erős szokássá válik! Már úton vagy!",
    "🔥 Tartsd meg ezt a lendületet! Egy kis ismétlés, és ezek a szavak örökre rögzülnek!",
    "🎯 Csak így tovább! Ha ezt a tempót tartod, hónapok múlva könnyedén beszélheted a nyelvet!",
    "✅ A siker egyik kulcsa a napi rutin! Azt csinálod, amit a nyelvtanulók legjobb 10%-a!",
    "🏆 Professzionális nyelvtanulók is így csinálják: Gyakorolj röviden, de rendszeresen – pontosan, ahogy te is!",
    "🌍 Nyitott világ vár rád! A jó eredményeid azt mutatják, hogy egyre könnyebben fogsz kommunikálni!",
  ],
  good: [
    "🌱 Szuper haladás! A hibák természetesek, hiszen az agy így tanul!",
    "💡 Tudtad? Az új információk rögzüléséhez 5-7 ismétlés szükséges! Csak gyakorolj tovább!",
    "📈 Már most is jól teljesítesz! Minden egyes gyakorlás segít, hogy a szavak mélyebben rögzüljenek!",
    "🧠 Az agyad folyamatosan fejlődik! Ne feledd, a nyelvtanulás maraton, nem sprint!",
    "⏳ Már napi 5-10 perc gyakorlás is hatalmas változást hozhat hosszú távon!",
    "🏋️ Az agyad olyan, mint egy izom! Minél többet használod, annál erősebb lesz!",
    "🔄 A space repetition (ismétlési módszer) segít, hogy a szavak hosszú távon megmaradjanak!",
    "🏗️ Építs szokásokat! Ha minden nap gyakorolsz, az új szavak automatikusan rögzülnek!",
    "🎯 Egyre jobban megy! A következő alkalommal már kevesebbet fogsz hibázni!",
    "🌟 Ne add fel! Az út, amin jársz, már most eredményeket hoz!",
  ],
  needsImprovement: [
    "🌱 Semmi baj, ha hibázol! Az agyadnak időre van szüksége a tanuláshoz!",
    "💪 A nehézségek természetesek! Mindenki így kezdi – folytasd, és látni fogod a fejlődést!",
    "⏳ Az agyad adaptálódik! A nyelvtanulás kezdetén minden nehéznek tűnik, de ez gyorsan változik!",
    "🛠️ A hibák nem akadályok, hanem lépcsőfokok a sikerhez!",
    "📚 Minél többször találkozol egy szóval, annál biztosabban fogod megjegyezni!",
    "🎯 A fókusz a fejlődésen van, nem a tökéletességen! Minden gyakorlás számít!",
    "🧠 A tudomány szerint: 30 nap következetes tanulás után az agy sokkal gyorsabban rögzít új információkat!",
    "🔄 Az ismétlés a kulcs! A legjobb nyelvtanulók is rengeteget hibáznak – de nem adják fel!",
    "🏗️ Lépésről lépésre építed fel a tudásodat! Hamarosan látni fogod a fejlődést!",
    "🚀 Egyetlen szó megtanulása is előrelépés! Folytasd, és napról napra könnyebb lesz!",
  ],
};

function getMotivationalMessage(accuracy: number) {
  if (accuracy > 75) {
    return motivationalMessages.excellent[
      Math.floor(Math.random() * motivationalMessages.excellent.length)
    ];
  } else if (accuracy >= 50) {
    return motivationalMessages.good[
      Math.floor(Math.random() * motivationalMessages.good.length)
    ];
  } else {
    return motivationalMessages.needsImprovement[
      Math.floor(Math.random() * motivationalMessages.needsImprovement.length)
    ];
  }
}

export default getMotivationalMessage;
