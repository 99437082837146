import React from "react";

import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import MoodBadIcon from "@mui/icons-material/MoodBad";

import { Button, Typography, TextField, Grid } from "@mui/material";
import transformWords from "../../../service/practice/transformWords";
import updateWordsList from "../../../service/practice/updateWordsList";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

export default function GoodAnswerForm(props: any) {
  const {
    tasks,
    tasksStatus,
    updateGoodAnswerElement,
    nextTask,
  } = props;
  const currentFormType = tasksStatus.type;
  const defaultAnswers = currentFormType === 0 ? tasks[tasksStatus.currentTask].hun : tasks[tasksStatus.currentTask].eng;
  const nouns = tasks[tasksStatus.currentTask].hun_nouns;
  const verbs = tasks[tasksStatus.currentTask].hun_verbs;
  const adjectives = tasks[tasksStatus.currentTask].hun_adjectives;
  const adverbs = tasks[tasksStatus.currentTask].hun_adverbs;
  const pronouns = tasks[tasksStatus.currentTask].hun_pronouns;
  const prepositions = tasks[tasksStatus.currentTask].hun_prepositions;
  const conjunctions = tasks[tasksStatus.currentTask].hun_conjunctions;
  const numerals = tasks[tasksStatus.currentTask].hun_numerals;
  const determiners = tasks[tasksStatus.currentTask].hun_determiners;
  const interjections = tasks[tasksStatus.currentTask].interjections ;
  const engSynonyms = tasks[tasksStatus.currentTask].eng_synonyms;
  const hunSynonyms = tasks[tasksStatus.currentTask].hun_synonyms;
  const solutions: string = currentFormType === 0 ? transformWords(defaultAnswers, nouns, verbs, adjectives, adverbs, pronouns, prepositions, conjunctions, numerals, determiners, interjections) :
  transformWords(defaultAnswers);

  const getStateButton = () => {
    // isUsed === "undefined" word did not have answer yet
    // good answer and not used
    if (typeof tasks[tasksStatus.currentTask].isUsed === "undefined") {
      return (
        <Grid container>
          {" "}
          {/* first encounter with the task */}
          <Grid p={1} xs={12} item>
            <Typography my={1} textAlign={"center"} fontWeight={600}>
              Mennyire tudtad?
            </Typography>
          </Grid>
          <Grid pr={1} xs={3} item>
            <Button
              type="submit"
              size="medium"
              fullWidth
              variant="contained"
              color="error"
              onClick={() => updateGoodAnswerElement("1")}
            >
              <MoodBadIcon fontSize="large" />
            </Button>
          </Grid>
          <Grid pr={1} xs={3} item>
            <Button
              type="submit"
              size="medium"
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => updateGoodAnswerElement("2")}
            >
              <SentimentNeutralIcon fontSize="large" />
            </Button>
          </Grid>
          <Grid pr={1} xs={3} item>
            <Button
              type="submit"
              size="medium"
              fullWidth
              variant="contained"
              color="info"
              onClick={() => updateGoodAnswerElement("3")}
            >
              <SentimentSatisfiedIcon fontSize="large" />
            </Button>
          </Grid>
          <Grid xs={3} item>
            <Button
              type="submit"
              size="medium"
              fullWidth
              variant="contained"
              color="success"
              onClick={() => updateGoodAnswerElement("4")}
            >
              <SentimentVerySatisfiedIcon fontSize="large" />
            </Button>
          </Grid>
        </Grid>
      );
    } else if (
      tasksStatus.currentProgress === 10 &&
      typeof tasks[tasksStatus.currentTask].isUsed === "boolean" &&
      tasks[tasksStatus.currentTask].isUsed
    ) {
      return (
        <Grid pr={1} mt={2} xs={12} textAlign={"center"} item>
          <Button
            type="submit"
            size="large"
            variant="contained"
            className="form-button"
            onClick={() => nextTask(true)}
          >
            Eredmény
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid pr={1} mt={2} xs={12} textAlign={"center"} item>
          <Button
            type="submit"
            size="large"
            variant="contained"
            className="form-button"
            onClick={() => updateGoodAnswerElement("3")}
          >
            Következő
          </Button>
        </Grid>
      );
    }
  };
  return (
    <Grid item xs={12}>
      <TextField
        className="form_input_field"
        margin="normal"
        fullWidth
        multiline
        rows={1}
        name="answer"
        id="answer"
        type="text"
        placeholder={
          tasksStatus.type === 1 ? "Írd be magyarul" : "Írd be angolul"
        }
        value={tasksStatus.userAnswer}
        required
        disabled
      />
      <Grid container>
        <Grid
          p={2}
          mb={2}
          xs={12}
          item
          sx={{ backgroundColor: "#121218", borderRadius: "10px" }}
        >
          <Grid container>
            <Grid m={"auto"} xs={12} item>
              <Typography
                fontSize={20}
                textAlign={"center"}
                sx={{
                  color: tasksStatus.wasPerfectAnswer
                    ? "greenyellow"
                    : "goldenrod",
                }}
                fontWeight={600}
              >
                {tasksStatus.responseMsg}
              </Typography>
            </Grid>
            <Grid m={"auto"} xs={12} item>
              <Typography
                fontSize={17}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
              >
                Egyéb Megoldás(ok)
              </Typography>
              <Typography
                fontSize={18}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
                sx={{ color: "#8bc34a" }}
              >
                {updateWordsList(solutions)}
              </Typography>
              <Typography
                fontSize={16}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
              >
                Szinonímák:
              </Typography>
              <Typography
                fontSize={15}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
                sx={{ color: "#8bc34a" }}
              >
                {updateWordsList(engSynonyms)}
              </Typography>
              <Typography
                fontSize={15}
                mt={1}
                textAlign={"center"}
                fontWeight={600}
                sx={{ color: "#8bc34a" }}
              >
                {updateWordsList(hunSynonyms)}
              </Typography>
              {tasks[tasksStatus.currentTask].usage_tips !== "" ? (
                <Typography
                  fontSize={11}
                  mt={1}
                  textAlign={"center"}
                  fontWeight={600}
                >
                  <TipsAndUpdatesIcon
                    sx={{
                      color: "yellow",
                      verticalAlign: "middle",
                      marginRight: 0.5,
                    }}
                  ></TipsAndUpdatesIcon>
                  {tasks[tasksStatus.currentTask].usage_tips}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {/* get current state button for action*/}
          {getStateButton()}
        </Grid>
      </Grid>
    </Grid>
  );
}
