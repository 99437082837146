import { Grid, Typography, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import moment from "moment";
import { indexUserData } from "../../../../../../models/response/afterLogin/indexUserData";
export default function UserHomeworkStatus(props: { user: indexUserData }) {
  const [isHomeworkStatusReady, setIsHomeworkStatusReady] = useState<
    JSX.Element | JSX.Element[] | undefined
  >(undefined);

  const getCurrentHomeworkStatus = (
    homeworkType: string,
    homeworkStatus: number | undefined
  ): JSX.Element | JSX.Element[] | undefined => {
    const homeworkData: any =
      typeof props.user.data !== "undefined" && props.user.data.homeworks_data;
    const getHomeworkType =
      homeworkType === "mistakes"
        ? "Gyakori hibák lecke"
        : "Régen gyakorolt szavak lecke";
    if (!props.user.isReady || typeof props.user.data === "undefined") {
      return;
    } else if (homeworkStatus === 0) {
      return (
        <div>
          <Typography my={0.5} component="span">{getHomeworkType} </Typography>
          <Typography my={0.5} sx={{ color: "var(--myNewGreen)" }}>
            &nbsp;Elérhető!
          </Typography>
          <Divider className="form-divider" />
        </div>
      );
    } else if (homeworkStatus === 1) {
      return (
        <div>
          <Typography my={0.5} component="span">
            {getHomeworkType}
          </Typography>
          <Typography my={0.5} sx={{ color: "var(--myNewGreen)" }}>
            &nbsp;Befejezve!
          </Typography>
          <Typography my={0.5}>
            Következő lecke -&nbsp;
            <Typography
              sx={{ color: "#8ACD43" }}
              component="span"
            >
              {moment((homeworkData[0] as any).next_task)
                .startOf("seconds")
                .fromNow()}
            </Typography>
          </Typography>
          <Divider className="form-divider" />
        </div>
      );
    } else {
      return (
        <div>
          <Typography my={0.5} component="span">
            {getHomeworkType}:
          </Typography>
          <Typography my={0.5}>
            Első lecke elkészül -&nbsp;
            <Typography
              my={0.5}
              component="span"
              sx={{ color: "#8ACD43" }}
            >
              {moment((homeworkData[0] as any).next_task)
                .startOf("seconds")
                .fromNow()}
            </Typography>
          </Typography>
          <Divider className="form-divider" />
        </div>
      );
    }
  };

  const getUserHomeworkInfo = (): JSX.Element | JSX.Element[] | undefined => {
    if (!props.user.isReady) {
      return;
    }
    // if the user did learn less than 20 words
    if ((props.user.data?.discovered_words as number) < 20) {
      return (
        <Grid
          m={"auto"}
          mt={1}
          pl={1}
          xs={12}
          item
          sx={{ display: "inline-block" }}
        >
          <Typography my={0.5} component="div">Gyakori hibák</Typography>
          <Typography my={0.5} component="div">
            Feltétel: Fedezz fel legalább 20 szót
          </Typography>
          <Divider className="form-divider" />
          <Typography my={0.5} component="div">
            Régen gyakorolt szavak
          </Typography>
          <Typography my={0.5} component="div">
            Feltétel: Fedezz fel legalább 50 szót
          </Typography>
          <Divider className="form-divider" />
        </Grid>
      );
    }
    //if the user did learn less than 50 words but more than 20
    else if (
      (props.user.data?.discovered_words as number) < 50 &&
      (props.user.data?.discovered_words as number) >= 20
    ) {
      return (
        <Grid
          m={"auto"}
          mt={1}
          pl={1}
          xs={12}
          item
          sx={{ display: "inline-block" }}
        >
          {getCurrentHomeworkStatus(
            "mistakes",
            props.user.data?.homework_mistakes
          )}
          <Typography my={0.5} component="div">
            Régen gyakorolt szavak
          </Typography>
          <Typography my={0.5} component="div">
            Feltétel: Fedezz fel legalább 50 szót
          </Typography>
          <Divider className="form-divider" />
        </Grid>
      );
    }
    // after user learnd at least 50 words, this returns with (mistakes, and old exercised words homework status)
    else {
      return (
        <Grid m={"auto"} mt={1} pl={1} xs={12} item>
          {getCurrentHomeworkStatus(
            "mistakes",
            props.user.data?.homework_mistakes
          )}
          {getCurrentHomeworkStatus(
            "oldwords",
            props.user.data?.homework_old_words
          )}
        </Grid>
      );
    }
  };

  useEffect(() => {
    if (props.user.isReady && isHomeworkStatusReady === undefined) {
      const homeworkInfo = getUserHomeworkInfo();
      setIsHomeworkStatusReady(homeworkInfo);
    }
    // eslint-disable-next-line
  }, [props.user.isReady, isHomeworkStatusReady]);
  return (
    <Grid mt={3} container sx={{ textAlign: "center", display: "block" }}>
      <Divider sx={{ width: "80%" }} className="form-divider" />
      <Typography component={"div"} m={"auto"} mt={1.5} sx={{ color: "gold" }}>
        - Automatikus Leckék állapota -
      </Typography>
      {isHomeworkStatusReady}
    </Grid>
  );
}
